import envConfigDev from "./config.dev.json";
import envConfigProd from "./config.prod.json";
import envConfigUat from "./config.uat.json";
import rootConfig from "./config.json";

interface Config {
    accessTokenName: string;
    authenticateApiUrl: string;
    authorizationCodeName: string;
    authWebUrl: string;
    connectWebUrl: string;
    databrokerApiUrl: string;
    deathEngineApiUrl: string;
    emailApiUrl: string;
    fastClientID: string;
    fasanoClientUrl: string;
    fasanoInternalUrl: string;
    faxApiUrl: string;
    fileApiUrl: string;
    /** The Auth0 application id for the Lexserv client web app */
    lexservClientID: string;
    lexservWebUrl: string;
    /** The Auth0 application id for the Longevity client web app */
    longevityClientID: string;
    longevityWebUrl: string;
    policyValuationApiUrl: string;
    servicingPdfApiUrl: string;
    siteMapApiUrl: string;
    servicingReportApiUrl: string;
    cloudApiBaseUrl: string;
    twentyFirstLEClientID: string;
    uwWebUrl: string;
}

const env: "dev" | "uat" | "prod" = import.meta.env.VITE_APP_ENVIRONMENT || "dev";
const envMap = {
    dev: envConfigDev,
    uat: envConfigUat,
    prod: envConfigProd
};

const envConfig: Config = envMap[env] as Config;

const configSettings: Config = {
    ...rootConfig,
    ...envConfig
};

export default configSettings;
