import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react";

export enum Role {
    Accounting = "Accounting",
    AccountingAdmin = "AccountingAdmin",
    GenericAdmin = "Admin",
    MedRecMgr = "MedRecMgr",
    ServicingOptReview = "ServicingOptReview",
    ServicingInsuredTrackingAdmin = "ServicingInsuredTrackingAdmin",
    ServicingPolicyManager = "ServicingPolicyManager",
    ServicingUser = "Servicing",
    ServicingAdmin = "ServicingAdmin",
    ServicingTrading = "ServicingTrading",
    UWSupportSr = "UWSupportSr",
    EmployeeViewSSN = "EmployeeViewSSN"
}

export interface Client {
    ClientID: number;
    ClientName: string;
}

export interface Portfolio {
    ClientID: number;
    ClientName: string;
    PortfolioID: number;
    PortfolioName: string;
}

export interface ClientInsured {
    ClientID: number;
    InsuredID: number;
    ClientTrackingID: string;
    CaseManager: string;
    Tracking: boolean;
    Notes: string;
    ObitSweep: boolean;
    ClientInsuredID?: number;
    CreatedDate?: Date | string;
    DeathNotificationDate?: Date | string | null;
    CreatedBy?: string;
    UpdatedDate?: Date | string;
    UpdatedBy?: string;
}

export interface FileType {
    FileTypeID: number;
    Name: string;
    InsuredDoc?: boolean;
    PolicyDoc?: boolean;
    PortfolioDoc?: boolean;
}

export interface GenericFile {
    highlighted?: boolean;
    selected?: boolean;
}

export type pane = {
    menuItem: {
        className?: string;
        color: SemanticCOLORS;
        content: string;
        icon: SemanticICONS;
        key: string;
    };
    render: () => React.ReactNode;
};
