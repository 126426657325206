import { CellOptions } from "@progress/kendo-react-excel-export";
import { DropdownItemProps } from "semantic-ui-react";

export const kendoPageSizes = { pageSizes: [25, 100] };
export const ssnRegex = /\d{3}-?\d{2}-?\d{4}/g;
export const MAX_FILE_DOWNLOAD_COUNT = 500;

export const cellOptions: CellOptions = { verticalAlign: "center", textAlign: "center" };
export const headerCellOptions: CellOptions = { textAlign: "center", bold: true };
export const columnOptions = { cellOptions, headerCellOptions };
export const tradingClientID = 5484;
export const PRESTON_CLIENT_ID = 4582;

export const states = [
    { state: "AK", statelong: "Alaska" },
    { state: "AL", statelong: "Alabama" },
    { state: "AR", statelong: "Arkansas" },
    { state: "AZ", statelong: "Arizona" },
    { state: "CA", statelong: "California" },
    { state: "CO", statelong: "Colorado" },
    { state: "CT", statelong: "Connecticut" },
    { state: "DC", statelong: "District of Columbia" },
    { state: "DE", statelong: "Delaware" },
    { state: "FL", statelong: "Florida" },
    { state: "GA", statelong: "Georgia" },
    { state: "HI", statelong: "Hawaii" },
    { state: "IA", statelong: "Iowa" },
    { state: "ID", statelong: "Idaho" },
    { state: "IL", statelong: "Illinois" },
    { state: "IN", statelong: "Indiana" },
    { state: "KS", statelong: "Kansas" },
    { state: "KY", statelong: "Kentucky" },
    { state: "LA", statelong: "Louisiana" },
    { state: "MA", statelong: "Massachusetts" },
    { state: "MD", statelong: "Maryland" },
    { state: "ME", statelong: "Maine" },
    { state: "MI", statelong: "Michigan" },
    { state: "MN", statelong: "Minnesota" },
    { state: "MO", statelong: "Missouri" },
    { state: "MS", statelong: "Mississippi" },
    { state: "MT", statelong: "Montana" },
    { state: "NC", statelong: "North Carolina" },
    { state: "ND", statelong: "North Dakota" },
    { state: "NE", statelong: "Nebraska" },
    { state: "NH", statelong: "New Hampshire" },
    { state: "NJ", statelong: "New Jersey" },
    { state: "NM", statelong: "New Mexico" },
    { state: "NV", statelong: "Nevada" },
    { state: "NY", statelong: "New York" },
    { state: "OH", statelong: "Ohio" },
    { state: "OK", statelong: "Oklahoma" },
    { state: "OR", statelong: "Oregon" },
    { state: "PA", statelong: "Pennsylvania" },
    { state: "PR", statelong: "Puerto Rico" },
    { state: "RI", statelong: "Rhode Island" },
    { state: "SC", statelong: "South Carolina" },
    { state: "SD", statelong: "South Dakota" },
    { state: "TN", statelong: "Tennessee" },
    { state: "TX", statelong: "Texas" },
    { state: "UT", statelong: "Utah" },
    { state: "VA", statelong: "Virginia" },
    { state: "VI", statelong: "Virigin Islands" },
    { state: "VT", statelong: "Vermont" },
    { state: "WA", statelong: "Washington" },
    { state: "WI", statelong: "Wisconsin" },
    { state: "WV", statelong: "West Virginia" },
    { state: "WY", statelong: "Wyoming" }
];

export const countries = [
    { Country: "Afghanistan", sortOrder: 2 },
    { Country: "Albania", sortOrder: 3 },
    { Country: "Algeria", sortOrder: 4 },
    { Country: "Andorra", sortOrder: 5 },
    { Country: "Angola", sortOrder: 6 },
    { Country: "Antigua & Deps", sortOrder: 7 },
    { Country: "Argentina", sortOrder: 8 },
    { Country: "Armenia", sortOrder: 9 },
    { Country: "Australia", sortOrder: 10 },
    { Country: "Austria", sortOrder: 11 },
    { Country: "Azerbaijan", sortOrder: 12 },
    { Country: "Bahamas", sortOrder: 13 },
    { Country: "Bahrain", sortOrder: 14 },
    { Country: "Bangladesh", sortOrder: 15 },
    { Country: "Barbados", sortOrder: 16 },
    { Country: "Belarus", sortOrder: 17 },
    { Country: "Belgium", sortOrder: 18 },
    { Country: "Belize", sortOrder: 19 },
    { Country: "Benin", sortOrder: 20 },
    { Country: "Bermuda", sortOrder: 21 },
    { Country: "Bhutan", sortOrder: 22 },
    { Country: "Bolivia", sortOrder: 23 },
    { Country: "Bosnia Herzegovina", sortOrder: 24 },
    { Country: "Botswana", sortOrder: 25 },
    { Country: "Brazil", sortOrder: 26 },
    { Country: "Brunei", sortOrder: 27 },
    { Country: "Bulgaria", sortOrder: 28 },
    { Country: "Burkina", sortOrder: 29 },
    { Country: "Burundi", sortOrder: 30 },
    { Country: "Cambodia", sortOrder: 31 },
    { Country: "Cameroon", sortOrder: 32 },
    { Country: "Canada", sortOrder: 33 },
    { Country: "Cape Verde", sortOrder: 34 },
    { Country: "Central African Rep", sortOrder: 35 },
    { Country: "Chad", sortOrder: 36 },
    { Country: "Chile", sortOrder: 37 },
    { Country: "China", sortOrder: 38 },
    { Country: "Colombia", sortOrder: 39 },
    { Country: "Comoros", sortOrder: 40 },
    { Country: "Congo", sortOrder: 41 },
    { Country: "Congo {Democratic Rep}", sortOrder: 42 },
    { Country: "Costa Rica", sortOrder: 43 },
    { Country: "Croatia", sortOrder: 44 },
    { Country: "Cuba", sortOrder: 45 },
    { Country: "Cyprus", sortOrder: 46 },
    { Country: "Czech Republic", sortOrder: 47 },
    { Country: "Denmark", sortOrder: 48 },
    { Country: "Djibouti", sortOrder: 49 },
    { Country: "Dominica", sortOrder: 50 },
    { Country: "Dominican Republic", sortOrder: 51 },
    { Country: "East Timor", sortOrder: 52 },
    { Country: "Ecuador", sortOrder: 53 },
    { Country: "Egypt", sortOrder: 54 },
    { Country: "El Salvador", sortOrder: 55 },
    { Country: "Equatorial Guinea", sortOrder: 56 },
    { Country: "Eritrea", sortOrder: 57 },
    { Country: "Estonia", sortOrder: 58 },
    { Country: "Ethiopia", sortOrder: 59 },
    { Country: "Fiji", sortOrder: 60 },
    { Country: "Finland", sortOrder: 61 },
    { Country: "France", sortOrder: 62 },
    { Country: "Gabon", sortOrder: 63 },
    { Country: "Gambia", sortOrder: 64 },
    { Country: "Georgia", sortOrder: 65 },
    { Country: "Germany", sortOrder: 66 },
    { Country: "Ghana", sortOrder: 67 },
    { Country: "Greece", sortOrder: 68 },
    { Country: "Grenada", sortOrder: 69 },
    { Country: "Guatemala", sortOrder: 70 },
    { Country: "Guinea", sortOrder: 71 },
    { Country: "Guinea-Bissau", sortOrder: 72 },
    { Country: "Guyana", sortOrder: 73 },
    { Country: "Haiti", sortOrder: 74 },
    { Country: "Honduras", sortOrder: 75 },
    { Country: "Hungary", sortOrder: 76 },
    { Country: "Iceland", sortOrder: 77 },
    { Country: "India", sortOrder: 78 },
    { Country: "Indonesia", sortOrder: 79 },
    { Country: "Iran", sortOrder: 80 },
    { Country: "Iraq", sortOrder: 81 },
    { Country: "Ireland {Republic}", sortOrder: 82 },
    { Country: "Israel", sortOrder: 83 },
    { Country: "Italy", sortOrder: 84 },
    { Country: "Ivory Coast", sortOrder: 85 },
    { Country: "Jamaica", sortOrder: 86 },
    { Country: "Japan", sortOrder: 87 },
    { Country: "Jordan", sortOrder: 88 },
    { Country: "Kazakhstan", sortOrder: 89 },
    { Country: "Kenya", sortOrder: 90 },
    { Country: "Kiribati", sortOrder: 91 },
    { Country: "Korea North", sortOrder: 92 },
    { Country: "Korea South", sortOrder: 93 },
    { Country: "Kosovo", sortOrder: 94 },
    { Country: "Kuwait", sortOrder: 95 },
    { Country: "Kyrgyzstan", sortOrder: 96 },
    { Country: "Laos", sortOrder: 97 },
    { Country: "Latvia", sortOrder: 98 },
    { Country: "Lebanon", sortOrder: 99 },
    { Country: "Lesotho", sortOrder: 100 },
    { Country: "Liberia", sortOrder: 101 },
    { Country: "Libya", sortOrder: 102 },
    { Country: "Liechtenstein", sortOrder: 103 },
    { Country: "Lithuania", sortOrder: 104 },
    { Country: "Luxembourg", sortOrder: 105 },
    { Country: "Macedonia", sortOrder: 106 },
    { Country: "Madagascar", sortOrder: 107 },
    { Country: "Malawi", sortOrder: 108 },
    { Country: "Malaysia", sortOrder: 109 },
    { Country: "Maldives", sortOrder: 110 },
    { Country: "Mali", sortOrder: 111 },
    { Country: "Malta", sortOrder: 112 },
    { Country: "Marshall Islands", sortOrder: 113 },
    { Country: "Mauritania", sortOrder: 114 },
    { Country: "Mauritius", sortOrder: 115 },
    { Country: "Mexico", sortOrder: 116 },
    { Country: "Micronesia", sortOrder: 117 },
    { Country: "Moldova", sortOrder: 118 },
    { Country: "Monaco", sortOrder: 119 },
    { Country: "Mongolia", sortOrder: 120 },
    { Country: "Montenegro", sortOrder: 121 },
    { Country: "Morocco", sortOrder: 122 },
    { Country: "Mozambique", sortOrder: 123 },
    { Country: "Myanmar, {Burma}", sortOrder: 124 },
    { Country: "Namibia", sortOrder: 125 },
    { Country: "Nauru", sortOrder: 126 },
    { Country: "Nepal", sortOrder: 127 },
    { Country: "Netherlands", sortOrder: 128 },
    { Country: "New Zealand", sortOrder: 129 },
    { Country: "Nicaragua", sortOrder: 130 },
    { Country: "Niger", sortOrder: 131 },
    { Country: "Nigeria", sortOrder: 132 },
    { Country: "Norway", sortOrder: 133 },
    { Country: "Oman", sortOrder: 134 },
    { Country: "Pakistan", sortOrder: 135 },
    { Country: "Palau", sortOrder: 136 },
    { Country: "Panama", sortOrder: 137 },
    { Country: "Papua New Guinea", sortOrder: 138 },
    { Country: "Paraguay", sortOrder: 139 },
    { Country: "Peru", sortOrder: 140 },
    { Country: "Philippines", sortOrder: 141 },
    { Country: "Poland", sortOrder: 142 },
    { Country: "Portugal", sortOrder: 143 },
    { Country: "Qatar", sortOrder: 144 },
    { Country: "Romania", sortOrder: 145 },
    { Country: "Russian Federation", sortOrder: 146 },
    { Country: "Rwanda", sortOrder: 147 },
    { Country: "Saint Vincent & the Grenadines", sortOrder: 150 },
    { Country: "Samoa", sortOrder: 151 },
    { Country: "San Marino", sortOrder: 152 },
    { Country: "Sao Tome & Principe", sortOrder: 153 },
    { Country: "Saudi Arabia", sortOrder: 154 },
    { Country: "Senegal", sortOrder: 155 },
    { Country: "Serbia", sortOrder: 156 },
    { Country: "Seychelles", sortOrder: 157 },
    { Country: "Sierra Leone", sortOrder: 158 },
    { Country: "Singapore", sortOrder: 159 },
    { Country: "Slovakia", sortOrder: 160 },
    { Country: "Slovenia", sortOrder: 161 },
    { Country: "Solomon Islands", sortOrder: 162 },
    { Country: "Somalia", sortOrder: 163 },
    { Country: "South Africa", sortOrder: 164 },
    { Country: "South Sudan", sortOrder: 165 },
    { Country: "Spain", sortOrder: 166 },
    { Country: "Sri Lanka", sortOrder: 167 },
    { Country: "Sudan", sortOrder: 168 },
    { Country: "Suriname", sortOrder: 169 },
    { Country: "Swaziland", sortOrder: 170 },
    { Country: "Sweden", sortOrder: 171 },
    { Country: "Switzerland", sortOrder: 172 },
    { Country: "Syria", sortOrder: 173 },
    { Country: "Taiwan", sortOrder: 174 },
    { Country: "Tajikistan", sortOrder: 175 },
    { Country: "Tanzania", sortOrder: 176 },
    { Country: "Thailand", sortOrder: 177 },
    { Country: "Togo", sortOrder: 178 },
    { Country: "Tonga", sortOrder: 179 },
    { Country: "Trinidad & Tobago", sortOrder: 180 },
    { Country: "Tunisia", sortOrder: 181 },
    { Country: "Turkey", sortOrder: 182 },
    { Country: "Turkmenistan", sortOrder: 183 },
    { Country: "Tuvalu", sortOrder: 184 },
    { Country: "Uganda", sortOrder: 185 },
    { Country: "Ukraine", sortOrder: 186 },
    { Country: "United Arab Emirates", sortOrder: 187 },
    { Country: "United Kingdom", sortOrder: 188 },
    { Country: "United States", sortOrder: 1 },
    { Country: "Uruguay", sortOrder: 189 },
    { Country: "Uzbekistan", sortOrder: 190 },
    { Country: "Vanuatu", sortOrder: 191 },
    { Country: "Vatican City", sortOrder: 192 },
    { Country: "Venezuela", sortOrder: 193 },
    { Country: "Vietnam", sortOrder: 194 },
    { Country: "Yemen", sortOrder: 195 },
    { Country: "Zambia", sortOrder: 196 },
    { Country: "Zimbabwe", sortOrder: 197 }
];

export const relationships = [
    { Relationship: "Accountant", sortOrder: 1 },
    { Relationship: "Adviser", sortOrder: 2 },
    { Relationship: "Agent", sortOrder: 3 },
    { Relationship: "Assistant", sortOrder: 4 },
    { Relationship: "Attorney", sortOrder: 5 },
    { Relationship: "Child", sortOrder: 6 },
    { Relationship: "Child-in-Law", sortOrder: 7 },
    { Relationship: "Cousin", sortOrder: 8 },
    { Relationship: "Co-Worker", sortOrder: 9 },
    { Relationship: "Ex-Spouse", sortOrder: 10 },
    { Relationship: "Friend", sortOrder: 11 },
    { Relationship: "Grandchild", sortOrder: 12 },
    { Relationship: "Insured", sortOrder: 13 },
    { Relationship: "Niece / Nephew", sortOrder: 14 },
    { Relationship: "Neighbor", sortOrder: 15 },
    { Relationship: "Partner", sortOrder: 16 },
    { Relationship: "Parent", sortOrder: 17 },
    { Relationship: "Physician", sortOrder: 18 },
    { Relationship: "Relative", sortOrder: 19 },
    { Relationship: "Sibling", sortOrder: 20 },
    { Relationship: "Spouse", sortOrder: 21 },
    { Relationship: "Stepchild", sortOrder: 22 },
    { Relationship: "Other", sortOrder: 23 },
    { Relationship: "Living Facility", sortOrder: 24 }
];

export const contactMethodOptions = [
    { value: 1, text: "Phone", key: 1 },
    { value: 2, text: "Mail", key: 2 },
    { value: 3, text: "Fax", key: 3 },
    { value: 4, text: "Email", key: 4 },
    { value: 5, text: "Signed Document", key: 5 },
    { value: 6, text: "Media", key: 6 }
];

export const trackingFrequencyOptions = [
    { key: 0, value: 0, text: "No Tracking" },
    { key: 1, value: 1, text: "Monthly" },
    { key: 3, value: 3, text: "Quarterly" },
    { key: 6, value: 6, text: "Semi-Annually" },
    { key: 12, value: 12, text: "Annually" }
];

export const statusTriggerOptions = [
    { value: "FundingDate", text: "Funding Date" },
    { value: "PolicyAnniversary", text: "Policy Anniversary" },
    { value: "PolicyMonthiversary", text: "Policy Monthiversary" },
    { value: "StartOfMonth", text: "Start of Month" }
];

export const premiumTriggerOptions = [
    { value: "FundingDate", text: "Funding Date" },
    { value: "PremiumFunding", text: "Premium Funding" },
    { value: "PolicyAnniversary", text: "Policy Anniversary" },
    { value: "PolicyMonthiversary", text: "Policy Monthiversary" },
    { value: "StartOfMonth", text: "Start of Month" }
];

export const leProviderOptions: DropdownItemProps[] = [
    { value: 1, key: 1, text: "21st Svcs" },
    { value: 2, key: 2, text: "AVS" },
    { value: 10, key: 10, text: "Clarity" },
    { value: 12, key: 12, text: "Elevation" },
    { value: 3, key: 3, text: "EMSI" },
    { value: 4, key: 4, text: "Fasano" },
    { value: 5, key: 5, text: "ISC" },
    { value: 11, key: 11, text: "Lapetus" },
    { value: 6, key: 6, text: "Longevity Services, Inc" },
    { value: 8, key: 8, text: "Midwest" },
    { value: 9, key: 9, text: "Polaris" },
    { value: 7, key: 7, text: "Predictive Resources LLC" }
];

export enum FileSubType {
    Standard = 2,
    Tertiary = 6
}

export enum MethodologyType {
    Standard = 7,
    Tertiary = 8
}

export const leMethodologyOptions: DropdownItemProps[] = [
    { value: MethodologyType.Standard, key: MethodologyType.Standard, text: "Standard" },
    { value: MethodologyType.Tertiary, key: MethodologyType.Tertiary, text: "Tertiary" }
];

export const fileSubTypeOptions: DropdownItemProps[] = [
    { value: 10, key: 10, text: "Hardcopy" },
    { value: 1, key: 1, text: "Preliminary" },
    { value: 3, key: 3, text: "Preliminary Rdct" },
    { value: 2, key: 2, text: "Standard" },
    { value: 4, key: 4, text: "Standard Rdct" },
    { value: 8, key: 8, text: "Standard XML" },
    { value: 6, key: 6, text: "Tertiary" },
    { value: 7, key: 7, text: "Tertiary Rdct" },
    { value: 9, key: 9, text: "Tertiary XML" },
    { value: 11, key: 11, text: "Data Enriched" }
];
