import { useCallback, useContext } from "react";

import { Auth0ClientAppMetadata } from "@itm21st/auth-core";
import { AuthContext } from "../components/AuthContextProvider";
import { DropdownItemProps } from "semantic-ui-react";
import { QuarterlyUpdateInsured } from "../features/mail-merge/QuarterlyUpdates";
import { QuarterlyUpdateSearchInsured } from "../features/mail-merge/QuarterlyUpdateSearch";
import { Role } from "../types";
import { User } from "auth0";
import { map } from "bluebird";
import { toast } from "react-toastify";
import { tradingClientID } from "../services/Constants";
import useDatabroker from "./useDatabroker";

interface UseDataServiceOutput {
    getClientOptions: () => Promise<DropdownItemProps[] | undefined>;
    getPortfolioOptions: () => Promise<DropdownItemProps[] | undefined>;
    createTrackingAttempts: (selectedInsureds: QuarterlyUpdateInsured[] | QuarterlyUpdateSearchInsured[]) => Promise<void>;
    provisionClientUser: (user: User<Auth0ClientAppMetadata>) => Promise<string | undefined>;
}

const useDataService = (): UseDataServiceOutput => {
    const databroker = useDatabroker();
    const { userHasRole } = useContext(AuthContext);

    const getClientOptions = useCallback(async (): Promise<DropdownItemProps[] | undefined> => {
        try {
            const { data, error } = await databroker.search<{ ClientID: number; NAME: string; Enabled: boolean }>({
                objectName: "MedDiag.dbo.tClient",
                select: ["ClientID", "NAME"],
                where: { Enabled: true, NAME: { notEqual: "", null: false } },
                orderBy: [["NAME", "ASC"]]
            });
            if (error || !data) throw new Error(error);
            const options = data.map((item) => ({
                key: item.ClientID,
                value: item.ClientID,
                text: `${item.NAME} - ${item.ClientID}`
            }));
            return userHasRole(Role.ServicingTrading) ? options : options.filter((item) => item.value !== tradingClientID);
        } catch (error) {
            console.error(error);
            toast.error(`Error fetching clients`);
        }
    }, [databroker, userHasRole]);

    const createTrackingAttempts = async (selectedInsureds: QuarterlyUpdateInsured[] | QuarterlyUpdateSearchInsured[]) => {
        try {
            await map(
                selectedInsureds,
                async (insured) => {
                    const { error } = await databroker.sproc({
                        objectName: "MedDiag.PM.InsertTrackingCycleAndAttempt",
                        parameters: {
                            InsuredID: insured.InsuredID,
                            ContactID: insured.SelectedContact.ContactID,
                            UserID: "USERID",
                            ContactMethodID: 2, // Mail
                            TrackingActionID: 4 // Request For Insured Update
                        }
                    });
                    if (error) throw new Error(error);
                },
                { concurrency: 5 }
            );
            toast.success("Tracking entries created");
        } catch (error) {
            console.error(error);
            toast.error("Error creating tracking entries");
        }
    };

    const getPortfolioOptions = useCallback(async (): Promise<DropdownItemProps[] | undefined> => {
        try {
            const { data, error } = await databroker.search<{
                PortfolioID: number;
                PortfolioName: string;
                Active: boolean;
                ClientID: number;
            }>({
                objectName: "MedDiag.PM.Portfolio",
                select: ["PortfolioID", "PortfolioName", "ClientID"],
                where: { Active: true },
                orderBy: [["PortfolioName", "ASC"]]
            });
            if (error || !data) throw new Error(error);
            const options = data.map((item) => ({
                clientid: item.ClientID, // React does not recognize the `ClientID` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `clientid` instead.
                key: item.PortfolioID,
                value: item.PortfolioID,
                text: `${item.PortfolioName} - ${item.PortfolioID}`
            }));
            return options;
        } catch (error) {
            console.error(error);
            toast.error(`Error fetching portfolios`);
        }
    }, [databroker]);

    const provisionClientUser = useCallback(
        async (user: User<Auth0ClientAppMetadata>): Promise<string | undefined> => {
            if (!user.user_id || !user.email) {
                throw new Error(`Auth0 user email is not available`);
            }
            // create or update the aspnet_Users record to create a UserID with the given AuthID
            const { data, error } = await databroker.sproc<{ UserID: string }>({
                objectName: "MedDiag.dbo.ProvisionClientUser",
                parameters: { AuthID: user.user_id, Username: user.email }
            });

            if (error || !data) throw new Error(error);

            const [{ UserID }] = data;
            return UserID;
        },
        [databroker]
    );

    return { getClientOptions, getPortfolioOptions, createTrackingAttempts, provisionClientUser };
};

export default useDataService;
