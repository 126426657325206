import App from "./App";
import AuthContextProvider from "./components/AuthContextProvider";
import React from "react";
import { createRoot } from "react-dom/client";
import qs from "qs";
import { storeToken } from "./services/StorageProvider";

const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const existingToken = query.access_token as string;
if (existingToken) {
    storeToken(existingToken);
    delete query.access_token;
    window.location.search = qs.stringify(query);
}

const contatiner = document.getElementById("root");
const root = createRoot(contatiner as HTMLElement);
root.render(
    <React.StrictMode>
        <AuthContextProvider>
            <App />
        </AuthContextProvider>
    </React.StrictMode>
);
