import Databroker from "@itm21st/databroker";
import config from "../config";
import { getToken } from "../services/StorageProvider";
import { useInternalDatabroker } from "@itm21st/databroker-react";

const baseURL = `${config.databrokerApiUrl}/internal`;

const useDatabroker = (): Databroker => {
    const databroker = useInternalDatabroker({
        baseURL,
        token: getToken() || ""
    });
    return databroker;
};

export default useDatabroker;
